import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Icon } from "react-icons-kit"
import { edit } from "react-icons-kit/fa/edit"
import AdmNavbar from "../../components/admNavbar"
import Footer from "../../components/footer"
import { getBlogsPage, deleteBlogsEntries } from "../../services/admin"
import { VerifyUserPermission } from "../../functions/verifyUserPermision"
import { formatDate } from "../../functions/formatDate"
import "../../css/multilogica-theme.scss"

import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Table,
  Modal,
} from "react-bootstrap"

import "bootstrap/dist/css/bootstrap.min.css"

function AdminBlogList() {
  const pageNumber = 0

  const [userVerified, setUserVerified] = useState(false)
  const [jwt, setJwt] = useState("")
  const [blogsData, setBlogsData] = useState([])
  const [selectedBlogs, setSelectedBlogs] = useState([])
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState(false)
  const [showDelSppiner, setShowDelSppiner] = useState(false)
  const [rebuildTable, setRebuildTable] = useState(false)

  useEffect(() => {
    setJwt(localStorage.getItem("jwt"))
  }, [])

  useEffect(() => {
    const getBlogsData = async () => {
      const data = {
        pageNumber: pageNumber,
        permission: "view-blog-list",
      }
      if (jwt !== "") {
        await getBlogsPage(jwt, data).then(res => {
          if (res.status === 201) {
            const sortedRes = res.data.sort((f,s)=>{
              if(f.publicationDate > s.publicationDate){
                return  -1;
              } else {
                return 1;
              }
            })
            setBlogsData(sortedRes)
          } else {
            console.log("erro", res)
          }
        })
      }
    }
    getBlogsData()
    setSelectedBlogs([])
    setRebuildTable(false)
  }, [jwt, setBlogsData, rebuildTable])

  useEffect(() => {
    const verifyUser = async () => {
      const userHasPermission = await VerifyUserPermission("use-img-manager")
      if (!userHasPermission) {
        navigate("/403")
      } else {
        setUserVerified(true)
      }
    }
    if (!userVerified) verifyUser()
  }, [userVerified])

  const toggleCheck = evt => {
    const selBlogs = selectedBlogs
    if (evt.target.checked) {
      if (!selBlogs.includes(evt.target.id)) {
        selBlogs.push(evt.target.id)
      }
    } else {
      if (selBlogs.includes(evt.target.id)) {
        selBlogs.splice(selBlogs.indexOf(evt.target.id), 1)
      }
    }
    setSelectedBlogs(selBlogs)
  }

  const askForDeleteBlogs = () => {
    if (selectedBlogs.length > 0) {
      setModalDeleteConfirm(true)
    }
  }

  const removeModal = () => {
    setModalDeleteConfirm(false)
  }

  const gotoBlogEditor = () => {
    localStorage.removeItem("editingBlog")
    navigate("/admin/blog-editor")
  }

  const deleteBlogs = async () => {
    setModalDeleteConfirm(false)
    setShowDelSppiner(true)
    if (jwt !== "") {
      const data = {
        blogsTodelete: selectedBlogs,
        permission: "delete-blog-entry",
      }
      await deleteBlogsEntries(jwt, data).then(res => {
        setShowDelSppiner(false)
        if (res.status === 201) {
          setRebuildTable(true)
        }
      })
    }
  }

  const editBlog = (evt, id) => {
    localStorage.setItem("editingBlog", JSON.stringify(blogsData[id]))
    navigate("/admin/blog-editor")
  }

  const getBlogList = () => {
    if (blogsData.length) {
      return (
        <Table striped responsive hover>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Título</th>
              <th>Publicado</th>
              <th>Publicação</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {blogsData.map((blogLine, k) => {
              return (
                <tr key={k}>
                  <td>
                    <div className="custom-control custom-checkbox custom-checkbox-green">
                      <input
                        onClick={evt => toggleCheck(evt)}
                        onChange={evt => toggleCheck(evt)}
                        type="checkbox"
                        className="custom-control-input custom-control-input-green"
                        id={blogLine.id}
                        // checked={
                        //   selectedBlogs.includes(blogLine.id) ? true : false
                        // }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={blogLine.id}
                      >
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td>{blogLine.title}</td>
                  <td>{blogLine.publishedContent ? "✅" : "❌"}</td>
                  <td>{formatDate(blogLine.publicationDate)}</td>
                  <td>
                    <Button size="sm" onClick={evt => editBlog(evt, k)}>
                      <Icon size={"18px"} icon={edit} />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <AdmNavbar />
      <Modal show={modalDeleteConfirm}>
        <Modal.Body>
          <p>Corfirmar a remoção destes blogs:</p>
          {Array.isArray(blogsData) ? (
            blogsData.map((blogLine, k) => {
              let ret
              if (selectedBlogs.includes(blogLine.id)) {
                ret = <p key={k}>{blogLine.title}</p>
              } else {
                ret = ""
              }
              return ret
            })
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button variant="danger" onClick={deleteBlogs}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={removeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="height-40" />
      <div>
        <Container>
          <Row>
            <Col>
              <Container className="info-page">
                <Row className="align-items-center">
                  <Col xs={12}>
                    <div className="msg">
                      <h1>Entradas de blog</h1>
                    </div>
                  </Col>
                </Row>
                {!userVerified ? (
                  <div>
                    <Spinner animation="border" size="sm" variant="primary" />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <Button
                          size="sm"
                          onClick={evt => gotoBlogEditor()}
                        >
                          Criar novo blog
                        </Button>
                        <div style={{ height: "20px" }} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          size="sm"
                          onClick={evt => askForDeleteBlogs(evt)}
                          style={{ float: "left" }}
                        >
                          Apagar os blogs selecionados
                        </Button>
                        <div
                          style={{
                            display: showDelSppiner ? "block" : "none",
                            float: "left",
                            margin: "4px 0 0 6px",
                          }}
                        >
                          <Spinner animation="border" variant="secondary" />
                        </div>
                        <div style={{ height: "20px" }} />
                        <div style={{ height: "20px" }} />
                        {getBlogList()}
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="height-40" />
      <Footer />
    </>
  )
}

export default AdminBlogList
